import React, { createContext, useContext, useState } from "react";

const UserContext = createContext();

export function UserContextProvider({ children }) {
  const [users, setUsers] = useState([]);
  const [me, setMe] = useState({});
  return (
    <UserContext.Provider value={{setUsers, users, me, setMe}} >
      {children}
    </UserContext.Provider>
  );
}

export function useUserContext() {
  return useContext(UserContext);
}
