import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { Dialog, Menu, Transition, Disclosure } from "@headlessui/react";
import cthulhu from "../media/cthulhu.png";
import placeholder from "../media/avatar-placeholder.jpeg";
import { Tooltip } from "react-tooltip";
import {
  ArrowRightStartOnRectangleIcon,
  AtSymbolIcon,
  Bars3Icon,
  BellIcon,
  ChatBubbleLeftIcon,
  CheckCircleIcon as CheckCircleIconOutline,
  ClipboardDocumentIcon,
  Cog6ToothIcon,
  HomeIcon,
  LinkIcon,
  MagnifyingGlassIcon,
  MapPinIcon,
  PlusIcon,
  UserCircleIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import Search from "./Search";
import { useAuth0 } from "@auth0/auth0-react";
import "react-tooltip/dist/react-tooltip.css";
import DarkModeToggle from "./DarkModeToggle";
import { useAppearance } from "../context/AppearanceContext";
import { useUserContext } from "context/UserContext";

export default function Navigation() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isSearchVisible, setSearchVisible] = useState(false);

  const { accentColor } = useAppearance();

  const { logout } = useAuth0();
  const { me } = useUserContext();

  const navigationContent = (
    <div
      className={`flex grow flex-col gap-y-3 overflow-y-auto bg-zinc-50 shadow dark:bg-zinc-900 border-r border-zinc-200 dark:border-zinc-800 pb-4`}
    >
      <div
        style={{ backgroundColor: accentColor }}
        className="flex flex-col gap-y-3 px-3 py-4 dark:!bg-zinc-950"
      >
        <div className="flex justify-between items-center shrink-0 items-center">
          <Link className="font-border" to="/">
            <img
              src={cthulhu}
              alt="Cthulhu Logo"
              className="w-7 h-7 object-cover"
            />
          </Link>

          <div className="flex items-center gap-x-3">
            <DarkModeToggle />

            <Menu as="div" className="inline-block text-left">
              <div className="flex items-center">
                <Menu.Button
                  className="inline-flex items-center w-full text-zinc-700 dark:text-zinc-300"
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Add"
                  data-tooltip-place="bottom"
                  data-tooltip-delay-show={1000}
                >
                  <PlusIcon className="my-auto h-5 w-5 text-white" />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-[6px] z-10 mt-4 py-2 px-1 w-[244px] origin-top-right rounded-md bg-zinc-50 dark:bg-zinc-900 shadow border border-zinc-300 dark:border-zinc-800 focus:outline-none">
                  {addNew.map((item) => {
                    return (
                      <Menu.Item key={item.name}>
                        <Link
                          href={item.href}
                          className="group flex py-1 px-2 rounded-md text-sm leading-6 text-zinc-700 hover:text-zinc-900 hover:bg-zinc-200 dark:text-zinc-300 dark:hover:text-white dark:hover:bg-zinc-800"
                        >
                          {item.name}
                        </Link>
                      </Menu.Item>
                    );
                  })}
                </Menu.Items>
              </Transition>
            </Menu>

            <Menu as="div" className="relative z-50">
              {me?.picture && (
                <Menu.Button className="flex items-center justify-center">
                  <img
                    className="h-7 w-7 rounded-full object-cover"
                    src={me.picture}
                    alt="Profile"
                  />
                </Menu.Button>
              )}
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="flex flex-col items-center absolute -right-[6px] mt-2.5 w-[244px] origin-top-right rounded-md bg-zinc-50 dark:bg-zinc-900 py-5 shadow-lg border border-zinc-300 dark:border-zinc-800 focus:outline-none z-[10000]">
                  <Menu.Item>
                    <div className="flex flex-col justify-center items-center gap-2 w-full pt-3 pb-5 border-b border-zinc-300 dark:border-zinc-800">
                      <div className="w-20 h-20 rounded-full">
                        <img
                          className="w-full h-full rounded-full bg-white"
                          src={me?.picture ?? placeholder}
                          alt="Profile"
                        />
                      </div>
                      <div className="flex flex-col items-center justify-center text-zinc-700 dark:text-zinc-300">
                        <h4 className="text-lg font-medium">
                          {me?.name ?? "name"}
                        </h4>
                        <h2 className="text-xs">{me?.Email ?? "email"}</h2>
                      </div>
                    </div>
                  </Menu.Item>
                  <div className="flex flex-col w-fit pt-3 text-zinc-700 dark:text-zinc-300">
                    <Link
                      to="/"
                      className="flex justify-start items-center gap-x-2 px-2 py-2 text-sm dark:text-white w-full"
                    >
                      <UserCircleIcon className="h-5 w-5" /> Profile
                    </Link>
                    <Link
                      to="/settings"
                      className="flex items-center gap-x-2 px-2 py-2 text-sm dark:text-white w-full"
                    >
                      <Cog6ToothIcon className="h-5 w-5" /> Settings
                    </Link>
                    <div
                      onClick={() =>
                        logout({ returnTo: window.location.origin })
                      }
                      className="flex items-center gap-x-2 px-2 py-2 text-sm dark:text-white cursor-pointer w-full"
                    >
                      <ArrowRightStartOnRectangleIcon className="h-5 w-5" />{" "}
                      Sign Out
                    </div>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-2 w-full">
          <Menu as="div" className="inline-block text-left">
            <div className="flex items-center">
              <Menu.Button
                className="inline-flex gap-2 items-center w-full"
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Tasks"
                data-tooltip-place="bottom"
                data-tooltip-delay-show={1000}
              >
                <div className="flex justify-center items-center gap-2 px-2 py-1.5 rounded w-full text-sm text-zinc-700 dark:text-zinc-300 ring-1 ring-inset ring-zinc-300 dark:ring-zinc-800 hover:bg-zinc-200 bg-zinc-50 dark:bg-zinc-900 cursor-pointer">
                  <CheckCircleIconOutline className="my-auto h-5 w-5" />{" "}
                  <span>1</span>
                </div>
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-[6px] z-10 mt-2 py-2 px-1 w-[244px] origin-top-right rounded-md bg-zinc-50 dark:bg-zinc-900 shadow border border-zinc-300 dark:border-zinc-800 focus:outline-none">
                {taskNavigation.map((notification) => {
                  return (
                    <Menu.Item key={notification.name}>
                      <Link
                        href={notification.href}
                        className="group flex gap-x-2 py-1 px-2 rounded-md text-sm leading-6 text-zinc-700 hover:text-zinc-900 hover:bg-zinc-200 dark:text-zinc-300 dark:hover:text-white dark:hover:bg-zinc-800"
                      >
                        {notification.name}
                      </Link>
                    </Menu.Item>
                  );
                })}
              </Menu.Items>
            </Transition>
          </Menu>

          <Menu as="div" className="inline-block text-left">
            <div className="flex items-center">
              <Menu.Button
                className="inline-flex items-center w-full"
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Notifications"
                data-tooltip-place="bottom"
                data-tooltip-delay-show={1000}
              >
                <div className="flex justify-center items-center gap-2 px-2 py-1.5 rounded w-full text-sm text-zinc-700 dark:text-zinc-300 ring-1 ring-inset ring-zinc-300 dark:ring-zinc-800 hover:bg-zinc-200 bg-zinc-50 dark:bg-zinc-900 cursor-pointer">
                  <BellIcon className="my-auto h-5 w-5" /> <span>3</span>
                </div>
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-[6px] z-10 mt-2 py-2 px-1 w-[244px] origin-top-right rounded-md bg-zinc-50 dark:bg-zinc-900 shadow border border-zinc-300 dark:border-zinc-800 focus:outline-none">
                {notificationNavigation.map((notification) => {
                  return (
                    <Menu.Item key={notification.name}>
                      <Link
                        href={notification.href}
                        className="group flex gap-x-2 py-1 px-2 rounded-md text-sm leading-6 text-zinc-700 hover:text-zinc-900 hover:bg-zinc-200 dark:text-zinc-300 dark:hover:text-white dark:hover:bg-zinc-800"
                      >
                        {notification.name}
                      </Link>
                    </Menu.Item>
                  );
                })}
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
        <div className="w-full">
          <div
            className="flex justify-center items-center gap-2 px-2 py-1.5 rounded w-full text-sm text-zinc-700 dark:text-zinc-300 ring-1 ring-inset ring-zinc-300 dark:ring-zinc-800 bg-zinc-50 hover:bg-zinc-200 dark:bg-zinc-900 cursor-pointer"
            onClick={() => setSearchVisible(true)}
          >
            <MagnifyingGlassIcon className="my-auto h-4 w-4" />{" "}
            <span>Search...</span>
          </div>
          {isSearchVisible && <Search setSearchVisible={setSearchVisible} />}
        </div>
      </div>
      <nav className="flex flex-1 flex-col dark:bg-zinc-900 px-2">
        <ul className="flex flex-1 flex-col gap-y-8">
          <li>
            <ul>
              {navigation?.map((item) => (
                <li key={item.name} onClick={() => setSidebarOpen(false)}>
                  <Link
                    to={item.href}
                    className="group flex gap-x-2 py-1.5 px-2 rounded-md text-sm leading-6 text-zinc-700 hover:text-zinc-900 hover:bg-zinc-200 dark:text-zinc-300 dark:hover:text-white dark:hover:bg-zinc-800"
                  >
                    <item.icon
                      className="my-auto h-5 w-5 shrink-0"
                      aria-hidden="true"
                    />
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </li>
          <li className="mt-auto" onClick={() => setSidebarOpen(false)}>
            <Link
              to="/settings/general"
              className="group flex gap-x-2 py-1.5 px-2 rounded-md text-sm leading-6 text-zinc-700 hover:text-zinc-900 hover:bg-zinc-200 dark:text-zinc-300 dark:hover:text-white dark:hover:bg-zinc-800"
            >
              <Cog6ToothIcon
                className="my-auto h-5 w-5 shrink-0"
                aria-hidden="true"
              />
              Settings
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );

  return (
    <>
      <Tooltip id="my-tooltip" className="z-[9999] !text-sm" />
      <div>
        <Disclosure
          as="nav"
          className="bg-white dark:bg-zinc-900 border-b border-zinc-200 dark:border-none lg:hidden"
        >
          <>
            <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
              <div className="relative flex h-16 items-center justify-between !w-full">
                <div className="inset-y-0 flex items-center">
                  <Disclosure.Button
                    className="relative inline-flex items-center justify-center rounded-md p-2 text-zinc-500 dark:text-zinc-400"
                    onClick={() => setSidebarOpen(true)}
                  >
                    <span className="absolute -inset-0.5" />
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  </Disclosure.Button>
                </div>

                <div className="flex flex-1 items-center justify-center px-2 lg:ml-6 lg:justify-end">
                  <div className="w-full max-w-lg">
                    <div className="relative">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <MagnifyingGlassIcon
                          className="h-5 w-5 text-zinc-500 dark:text-zinc-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div
                        className="block w-full rounded-md border-0 bg-white dark:bg-zinc-800 py-1.5 pl-10 pr-3 text-zinc-500 dark:text-zinc-400 ring-1 ring-inset ring-gray-300 dark:ring-zinc-700 text-sm sm:leading-6 cursor-pointer"
                        onClick={() => setSearchVisible(true)}
                      >
                        Search...
                      </div>
                    </div>
                  </div>
                </div>

                <div className="inset-y-0 flex items-center pr-2">
                  <Menu as="div" className="relative ml-3">
                    <div>
                      <Menu.Button className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                        <span className="absolute -inset-1.5" />
                        <img
                          className="h-8 w-8 rounded-full object-cover"
                          src={me?.picture ?? cthulhu}
                          alt="Profile"
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          <Link
                            href="/"
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          >
                            Your Profile
                          </Link>
                        </Menu.Item>
                        <Menu.Item>
                          <Link
                            href="/"
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          >
                            Settings
                          </Link>
                        </Menu.Item>
                        <Menu.Item>
                          <Link
                            href="/"
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          >
                            Sign out
                          </Link>
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
          </>
        </Disclosure>

        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-zinc-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-64 flex-1">
                  {navigationContent}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        <div className="hidden relative lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-64 lg:flex-col">
          {navigationContent}
        </div>
      </div>
    </>
  );
}

const navigation = [
  { name: "Dashboard", href: "/", icon: HomeIcon },
  { name: "Quotes", href: "/quote", icon: ChatBubbleLeftIcon },
  { name: "Tasks", href: "/task", icon: CheckCircleIconOutline },
  { name: "Location Analysis", href: "/", icon: MapPinIcon },
  { name: "Referrals", href: "/", icon: LinkIcon },
  { name: "Users", href: "/", icon: UserIcon },
  { name: "Autoclearance", href: "/autoclearance", icon: AtSymbolIcon },
];

const notificationNavigation = [
  { name: "Notification 1", href: "/" },
  { name: "Notification 2", href: "/" },
  { name: "Notification 3", href: "/" },
];

const taskNavigation = [
  { name: "Task 1", href: "/" },
  { name: "Task 2", href: "/" },
  { name: "Task 3", href: "/" },
];

const addNew = [
  {
    name: "Quick Quote",
    icon: ClipboardDocumentIcon,
  },
  {
    name: "Quote",
    icon: ChatBubbleLeftIcon,
  },
  {
    name: "Task",
    icon: CheckCircleIconOutline,
  },
];
