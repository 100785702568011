import "./App.css";
import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import { AppearanceProvider, useAppearance } from "./context/AppearanceContext";
import Loader from "./components/loaders/Loader";
import Breadcrumbs from "./components/Breadcrumbs";
import Navigation from "./components/Navigation";
import EditProgram from "./components/settings/programs/EditProgram";
import Home from "./components/Dashboard";
import Task from "./components/task/Task";
import Tasks from "./components/task/Tasks";
import Programs from "./components/Programs";
import Quote from "./components/quote/Quote";
import Stepper from "./components/stepper/Stepper";
import Settings from "./components/settings/Settings";
import Autoclearance from "components/autoclearance/AutoClearance";
import FuzzyConfiguration from "components/autoclearance/FuzzyConfig";
import Summary from "./components/steps/summary/SummaryNavigation";
import OCRConfig from "components/autoclearance/AutoClearanceOCRAnalyze";
import { useUserContext } from "context/UserContext";

axios.defaults.baseURL = process.env.REACT_APP_BASE_API_PATH;
axios.defaults.timeout = 15000; // 15 sec for timeout.

export default function App() {
  const {
    error,
    isLoading: auth0Loading,
    isAuthenticated,
    user,
    getAccessTokenSilently,
    getAccessTokenWithPopup,
    loginWithRedirect,
  } = useAuth0();

  const ProtectedRoute = ({ component, ...propsForComponent}) => {
    const Cp = withAuthenticationRequired(component);
    return <Cp {...propsForComponent} />
  }

  // prefetch users.
  const { setUsers, setMe } = useUserContext();
  const { isLoading: usersLoading, refetch: userRefetch } = useQuery({
    queryKey: ['all_users'],
    queryFn: async () => { 
      if(!isAuthenticated) {
        return null;
      }
      else {
        const users = await axios.get('/user/user').then(x => x?.data);
        setUsers(users);
        return users;
      }
    },
    staleTime: 60 * 1000 * 10, // 20 minutes
  });

  useEffect(() => {
    if(!user) {
      setMe({});
    }
    else {
      const claim_namespace = 'https://claims.vnext.com';
      setMe({
        ... (user ?? {}),
        ...(user[`${claim_namespace}/user`] ?? {}),
        ...(user[`${claim_namespace}/app`] ?? {}),
        picture: user.picture,
      });
    }
  }, [user]);

  const [token, setToken] = useState(false);
  const {} = useQuery({
    queryKey: ['token', isAuthenticated, auth0Loading],
    staleTime: 1000 * 60 * 60 * 4, // four hours
    queryFn: async () => { 
      if(auth0Loading || !isAuthenticated)
        return '';
      let accessToken = null;
      const domain = `https://${process.env.REACT_APP_AUTH_DOMAIN}/api/v2/`;
      try {
        accessToken = await getAccessTokenSilently({
          audience: domain,
          cacheMode: 'on',
        });
      }
      catch {
        accessToken = await getAccessTokenWithPopup({
          audience: domain,
          cacheMode: 'on',
        });
      }
      if(accessToken === null || accessToken === undefined || token == accessToken)
        return '';
      setToken(accessToken);
      console.log(accessToken);
      //axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
      axios.interceptors.request.use((config) => {
        config.headers.Authorization = `Bearer ${accessToken}`;
        return config;
      });
      userRefetch();
      return accessToken;
    },
  });

  // Auto redirect to login.
  useEffect(() => {
    if (!auth0Loading && !isAuthenticated) {
      loginWithRedirect();
    }
  }, [auth0Loading, isAuthenticated, loginWithRedirect]);

  if (auth0Loading || usersLoading || token == false)
    return (
      <div className="flex justify-center items-center h-screen w-full bg-white dark:bg-zinc-950">
        <Loader />
      </div>
    );
  if (error) return <div>{error.message}</div>;

  const formattedUrl = (url) => url.toLowerCase().replace(/\s+/g, "-");
  const toastTheme = localStorage.getItem("theme") || "light";

  return (
    <>
      <ToastContainer theme={toastTheme} className="z-[9999]" />
      <AppearanceProvider>
        <Navigation format={formattedUrl} />
        <main className="relative min-h-screen bg-white dark:bg-zinc-950 lg:ml-64">
          <LayoutWrapper>
            <Breadcrumbs />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route
                path="/settings"
                element={<Navigate replace to="/settings/general" />}
              />
              <Route path="/settings/:id" element={<Settings />} />
              <Route
                path="/stepper"
                element={<Stepper/>}
              />

              <Route
                path="/settings/programs/:id/:title?"
                element={<EditProgram />}
              />
              <Route path="/task" element={<Tasks format={formattedUrl} />} />
              <Route
                path="/task/:id"
                element={<Task format={formattedUrl} />}
              />


              {/* Delete me? */}
              <Route path="/skeletor" element={<Loader/>} />


              <Route path="/quote" element={<ProtectedRoute component={Quote} />} />
              <Route path="/quote/programs" element={<ProtectedRoute component={Programs} />} />
              <Route path="/quote/:quoteVersionId/:stepId" element={<ProtectedRoute component={Stepper} />} />
              <Route path="/quote/review/:quoteVersionId/:title" element={<ProtectedRoute component={Summary} />} />
              <Route path="/autoclearance" element={<ProtectedRoute component={Autoclearance} />} />
              <Route path="/autoclearance/ocr" element={<ProtectedRoute component={OCRConfig} />} />
              <Route path="/autoclearance/fuzzy" element={<ProtectedRoute component={FuzzyConfiguration} />} />
              {/*
              <Route path="/autoclearance/tabs" element={<Navigate replace to="/autoclearance/tabs/config" />} />
              <Route
                path="/autoclearance/tabs/:stepId"
                element={<AutoclearanceTabs />}
              />
              */}
            </Routes>
          </LayoutWrapper>
        </main>
      </AppearanceProvider>
    </>
  );
}

function LayoutWrapper({ children }) {
  const { layoutWidth } = useAppearance();
  return (
    <div className={`${layoutWidth === "fixed" ? "mx-auto max-w-7xl" : ""}`}>
      {children}
    </div>
  );
}
